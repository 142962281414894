<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Markera som avslutad?</DefaultModalTitle>
    </template>

    <template slot="body">
      <p class="swap-description">Ärendet blir markerat som avslutad.</p>
    </template>

    <template slot="footer">
      <BaseButton
        :is-warning="true"
        :is-loading="loading"
        :is-rounded="true"
        :icon="svgs.ICONS.ERROR_WHITE"
        @click="cancelApplication"
      >
        Markera som avslutad
      </BaseButton>
      <BaseButton
        :is-disabled="loading"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import { mapActions } from 'vuex';
// import { events, features } from '@/utils/trackingEvents';
// import churnService from '@/utils/churn360';
import svgs from '@/utils/icon-constants';

import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'OvCloseModal',
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      svgs,
      loading: false
    };
  },

  methods: {
    ...mapActions({
      closeOvApp: 'applications/closeOvApp'
    }),
    async cancelApplication() {
      this.loading = true;
      await this.closeOvApp({ id: this.applicationId });
      // churnService.track(
      //   events.CHANGE_SWAP_STATUS,
      //   features.CHANGE_SWAP_STATUS.ABORT
      // );
      this.loading = false;
      this.$emit('cancelled');
    }
  }
};
</script>

<style scoped>
.swap-description {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}
</style>
