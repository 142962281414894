import http from '@/services/http';
import { APP_NAMES } from '@/utils/constants';

async function getTags({ rootState, commit }) {
  const type = rootState.app.activeApp === APP_NAMES.BYTESANSOKAN ? 'ba' : 'ah';
  const tags = await http.getAvailableTags(type);
  commit('setTags', tags);
  commit('applications/setFilterTags', tags, { root: true });
}

async function addTag({ state, rootState, commit }, tagId) {
  await http.addTag(state.tagsActiveApplicationId, tagId);
  if (rootState.app.activeApp === APP_NAMES.BYTESANSOKAN) {
    commit(
      'applications/addApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  } else if (rootState.app.activeApp === APP_NAMES.ANDRAHAND) {
    commit(
      'applications/addAhApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  } else if (
    [APP_NAMES.OVERLATELSER_PRIVATE, APP_NAMES.OVERLATELSER_COMPANY].includes(
      rootState.app.activeApp
    )
  ) {
    commit(
      'applications/addOvApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  }
}

async function removeTag({ state, rootState, commit }, tagId) {
  await http.removeTag(state.tagsActiveApplicationId, tagId);
  if (rootState.app.activeApp === APP_NAMES.BYTESANSOKAN) {
    commit(
      'applications/removeApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  } else if (rootState.app.activeApp === APP_NAMES.ANDRAHAND) {
    commit(
      'applications/removeAhApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  } else if (
    [APP_NAMES.OVERLATELSER_PRIVATE, APP_NAMES.OVERLATELSER_COMPANY].includes(
      rootState.app.activeApp
    )
  ) {
    commit(
      'applications/removeOvApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  }
}

export default {
  getTags,
  addTag,
  removeTag
};
