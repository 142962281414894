var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',[_vm._l((_vm.otherCheckboxes),function(otherCheckbox){return _c('li',{key:otherCheckbox.category,staticClass:"list-items"},[_c('ApplicationListCheck',{attrs:{"hide-icon":true,"is-disabled":_vm.isIncoming || _vm.isRemoved,"is-checked":_vm.checkedFiles.includes(otherCheckbox.category),"icon-url":_vm.getCheckboxIconUrl(otherCheckbox.category),"comment":null,"has-files":false,"icon-title":_vm.getCheckboxTitle(otherCheckbox.category)},on:{"checked":function($event){return _vm.$emit('checksChange', {
          checked: $event,
          category: otherCheckbox.category
        })},"selectedComment":function($event){return _vm.$emit('selectedComment', $event)}}},[_vm._v(_vm._s(_vm.getLabel(otherCheckbox.category))+": "+_vm._s(otherCheckbox.value)+" ")])],1)}),_vm._l((_vm.allDocumentsUploadedForType),function(category){return _c('li',{key:category,staticClass:"list-items"},[_c('ApplicationListCheck',{attrs:{"hide-icon":!_vm.isIncoming,"is-disabled":_vm.isIncoming || _vm.isRemoved,"is-checked":_vm.checkedFiles.includes(category),"icon-url":_vm.getCheckboxIconUrl(category),"comment":null,"has-files":!_vm.isIncoming && _vm.fileExists(category),"icon-title":_vm.getCheckboxTitle(category)},on:{"checked":function($event){return _vm.$emit('checksChange', {
          checked: $event,
          category,
          categoryType: _vm.activeCategoryType
        })},"selectedComment":function($event){return _vm.$emit('selectedComment', $event)},"openFile":function($event){return _vm.$emit('openFile', {
          index: 0,
          category,
          categoryType: _vm.activeCategoryType
        })}}},[_vm._v(_vm._s(_vm.getLabel(category))+" ")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }