<template>
  <ErrorModal v-if="!isLoading && errorModalVisible" @close="$emit('close')" />
  <div v-else-if="isLoading" class="loading-wrapper">
    <BaseLoadingSpinner :with-overlay="false" />
  </div>
  <ApplicationContainerModal
    v-else
    v-esc-click="handleEscClick"
    v-on="$listeners"
  >
    <template slot="modals">
      <OvNotesModal
        v-if="currentModal === 'NOTES'"
        :unread-notes="application.unreadNotes"
        :application-id="`${application.id}`"
        :read-only-mode="false"
        :notes="application.landlordNotes"
        @close="currentModal = null"
        @addNote="handleAddNote"
        @deleteNote="handleDeleteNote"
        @editNote="handleEditNote"
      />
      <OvDocumentsModal
        v-if="currentModal === 'DOCUMENTS'"
        :application-id="`${application.id}`"
        :selected-name="applicantName"
        :current-tenant-name="currentTenants[0].name"
        :secondary-tenant-name="secondaryTenants[0].name"
        :files="application.files"
        :selected-type="selectedDocumentType"
        :selected-document-category-type="selectedDocumentCategoryType"
        :selected-applicant-id="selectedApplicantId"
        :has-scrive-document="!!application.scriveId"
        @close="
          currentModal = null;
          selectedApplicantId = null;
          selectedDocumentType = null;
          selectedDocumentCategoryType = null;
        "
      />

      <OvApprovalModal
        v-if="currentModal === 'APPROVE'"
        :application-id="application.id"
        :initial-transfer-date="application.actualTransferDate"
        :transfer-date="application.reason.transferDate"
        :rent-to="application.reason.rentTo"
        @close="currentModal = null"
        @onApproved="leaveApplication"
      />

      <OvRejectModal
        v-if="currentModal === 'REJECT'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRejected="leaveApplication"
      />

      <OvPartInfoModal
        v-if="selectedPartInfo !== null"
        :position="selectedPartInfoModalPosition"
        :selected-info="selectedPartInfo"
        @close="selectedPartInfo = null"
      />

      <NotAssignedModal
        v-if="currentModal === 'USER_NOT_ASSIGNED'"
        @close="currentModal = null"
        @confirm="currentModal = 'REMOVE'"
      />

      <OvRemoveModal
        v-if="currentModal === 'REMOVE'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRemoved="leaveApplication"
      />

      <OvCloseModal
        v-if="currentModal === 'CLOSE'"
        :application-id="application.id"
        @close="currentModal = null"
        @cancelled="leaveApplication"
      />
    </template>
    <template slot="header">
      <TabHeader
        :all-disabled="isAnimatingModalSize"
        :title="getReasonText"
        :subtitle="tabHeaderSubtitle"
        :application-status="application.landlordStatus"
        :show-help-button="false"
        :tabs="activeTabs"
        :is-archived="isArchived"
        @tabClick="setTab"
      ></TabHeader>
    </template>
    <template slot="content">
      <template v-if="activeTab === 1">
        <CompanyRows
          v-if="isCompanyApplication"
          :application="application"
          :other-checkboxes="otherCheckboxes"
          @openFile="openDocumentModal($event)"
          @checksChange="updateCheckedMember($event)"
        />
        <PrivateRows
          v-else
          :application="application"
          :other-checkboxes="otherCheckboxes"
          @openFile="openDocumentModal($event)"
          @checksChange="updateCheckedMember($event)"
        />
      </template>
      <!-- START ACTIVETAB 2 -->
      <template v-if="activeTab === 2">
        <div v-if="userHistory.length" class="grid">
          <div
            v-for="(userApp, userAppIndex) in userHistory"
            :key="userApp.id + userAppIndex"
            class="application-card-wrapper"
          >
            <OvApplicationCard
              :application="userApp"
              :reason-type-title="ovReasonText(userApp.reason)"
              :is-new="false"
              :enable-link="currentApplicationId !== userApp.id"
              :full-name="userApp.assignedLandlordFirstName"
              :last-name="userApp.assignedLandlordLastName"
              status-date=""
              :show-tag-button="false"
              :show-transfer-date="false"
              :to-path="getToPath(userApp.id)"
            />
            <p v-if="currentApplicationId === userApp.id" class="is-same-card">
              <ChevronDownIcon />
              <span>Du har detta ärende öppet</span>
            </p>
          </div>
        </div>
        <div v-else>
          <h3 style="font-size: 2rem">Ingen historik finns ännu</h3>
        </div>
      </template>
      <!-- END ACTIVETAB 2 -->
    </template>
    <template slot="footer">
      <div class="left">
        <BaseItemsList
          :class="{
            'no-pointer-events': hasRemovalTypeStatus
          }"
          :items="landlordUsersWithUnAssign.filter(x => !x.inactivatedAt)"
          :show-contact="true"
          @click="assignLandlordUser($event)"
        >
          <BaseIconText
            v-if="!application.assignedLandlordUserId"
            :icon-url="svgs.ICONS.ADMINISTRATOR"
            size="larger"
            class="assign-button"
          >
            {{ hasRemovalTypeStatus ? 'Ej tilldelad' : 'Tilldela handläggare' }}
          </BaseIconText>

          <UserIcon
            v-else
            :first-name="application.assignedLandlordFirstName"
            :last-name="application.assignedLandlordLastName"
            :full-name-visible="true"
          ></UserIcon>
        </BaseItemsList>

        <div v-if="!isIncoming && !hasRemovalTypeStatus">
          <button
            class="simple-button notes-button"
            @click="currentModal = 'NOTES'"
          >
            <BaseIconText :icon-url="svgs.ICONS.NOTEBOOK" size="larger">
              <NotificationSymbol
                v-if="false"
                slot="icon"
                border-color="#f1f2f6"
              />Noteringar</BaseIconText
            >
          </button>
        </div>

        <button
          v-if="!isIncoming && !hasRemovalTypeStatus"
          class="simple-button files-button"
          @click="currentModal = 'DOCUMENTS'"
        >
          <BaseIconText :icon-url="svgs.ICONS.ATTACHMENT" size="larger"
            >Visa {{ application.files?.length || 0 }} filer
          </BaseIconText>
        </button>
      </div>
      <div class="right">
        <div
          v-if="!isIncoming && !hasRemovalTypeStatus"
          class="popupModal-container mr-15"
        >
          <PopupModal
            v-if="popupModalVisible"
            :items="itemsInPopupModal"
            @close="popupModalVisible = false"
            @click="handlePopupClick"
          />
          <button
            class="simple-button notes-button"
            @click="popupModalVisible = !popupModalVisible"
          >
            <BaseIconText :icon-url="svgs.ICONS.MORE">Fler val</BaseIconText>
          </button>
        </div>
        <template v-if="application.landlordStatus === 'READY'">
          <BaseButton
            class="mr-15"
            :is-warning="true"
            :is-rounded="true"
            :icon="svgs.ICONS.ERROR_WHITE"
            @click="currentModal = 'REJECT'"
          >
            Neka ansökan
          </BaseButton>

          <BaseButton
            :is-rounded="true"
            :icon="svgs.ICONS.CHECK_WHITE"
            @click="currentModal = 'APPROVE'"
          >
            Godkänn ansökan
          </BaseButton>
        </template>
        <BaseButton
          v-else-if="application.landlordStatus === 'APPROVED'"
          :is-warning="true"
          :is-rounded="true"
          :icon="svgs.ICONS.ERROR_WHITE"
          @click="currentModal = 'CLOSE'"
        >
          Avsluta uthyrning
        </BaseButton>
        <DefaultModal
          v-if="showRejectReasonModal"
          :show-close-btn="false"
          @close="rejectReasonModalVisible = false"
        >
          <template slot="header">
            <DefaultModalTitle>Nekat byte</DefaultModalTitle>
          </template>

          <template slot="body">
            <div class="reject-reason">
              <p class="explanation">Förklaring:</p>

              <span v-if="landlordRejectionNote.text"
                >"{{ landlordRejectionNote.text }}"</span
              >
              <span v-else>(Förklaring saknas)</span>
              <p v-if="landlordRejectionNote.firstName">
                - {{ landlordRejectionNote.firstName }}
                {{ landlordRejectionNote.lastName }}
              </p>
              <p v-else>(Namn saknas)</p>
            </div>
          </template>

          <template slot="footer">
            <BaseButton
              :is-rounded="true"
              :is-default="true"
              @click="rejectReasonModalVisible = false"
              >Stäng</BaseButton
            >
          </template>
        </DefaultModal>
      </div>
    </template>
  </ApplicationContainerModal>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import svgs from '@/utils/icon-constants';
import { ovReasonText } from '@/utils/ovUtils';
import validDate from '@/utils/validDate';
import statusTypes, { readableStatusText } from '@/utils/statusTypes';
import { APP_NAMES, APP_PATHS } from '@/utils/constants';

import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';
import TabHeader from '@/components/TabHeader.vue';
import NotificationSymbol from '@/components/NotificationSymbol.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import escClick from '@/directives/esc-click';
import PopupModal from '@/components/PopupModal.vue';
import OvPartInfoModal from '@/components/OvPartInfoModal.vue';
import ApplicationContainerModal from '@/components/ApplicationContainerModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import OvNotesModal from '@/components/OvNotesModal.vue';
import OvDocumentsModal from '@/components/OvDocumentsModal.vue';
import OvApprovalModal from '@/components/OvApprovalModal.vue';
import OvRejectModal from '@/components/OvRejectModal.vue';
import OvCloseModal from '@/components/OvCloseModal.vue';
import BaseItemsList from '@/components/BaseItemsList.vue';
import UserIcon from '@/components/UserIcon.vue';
import NotAssignedModal from '@/components/NotAssignedModal.vue';
import OvRemoveModal from '@/components/OvRemoveModal.vue';
import OvApplicationCard from '@/components/OvApplicationCard.vue';
import DefaultModal from '@/components/DefaultModal.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import ChevronDownIcon from '@/components/Icons/ChevronDownIcon.vue';

import CompanyRows from './CompanyRows.vue';
import PrivateRows from './PrivateRows.vue';

export default {
  name: 'OvApplicationView',
  directives: { escClick },
  components: {
    BaseIconText,
    BaseButton,
    OvNotesModal,
    TabHeader,
    NotificationSymbol,
    ErrorModal,
    PopupModal,
    ApplicationContainerModal,
    BaseLoadingSpinner,
    OvDocumentsModal,
    OvApprovalModal,
    OvRejectModal,
    BaseItemsList,
    UserIcon,
    OvPartInfoModal,
    NotAssignedModal,
    OvRemoveModal,
    OvCloseModal,
    OvApplicationCard,
    DefaultModal,
    DefaultModalTitle,
    ChevronDownIcon,
    CompanyRows,
    PrivateRows
  },
  data() {
    return {
      svgs,
      currentModal: '',
      application: null,
      isAnimatingModalSize: false,
      isLoading: false,
      errorModalVisible: false,
      popupModalVisible: false,
      itemsInPopupModal: [
        // {
        //   title: 'Avbryt bytet',
        //   icon: svgs.ICONS.CHECK_NOTE_GREY,
        //   content:
        //     'Om en hyresgäst inte längre vill gå vidare med bytet, så flyttas ärendet till avslutat men markeras som avbrytet.',
        //   cta: 'Vill du avbryta bytet?',
        //   type: 'ABORT'
        // },
        {
          title: 'Radera ansökan',
          icon: `<svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.88232 16C8.14754 16 8.40189 15.8946 8.58943 15.7071C8.77697 15.5196 8.88232 15.2652 8.88232 15V9C8.88232 8.73478 8.77697 8.48043 8.58943 8.29289C8.40189 8.10536 8.14754 8 7.88232 8C7.61711 8 7.36275 8.10536 7.17522 8.29289C6.98768 8.48043 6.88232 8.73478 6.88232 9V15C6.88232 15.2652 6.98768 15.5196 7.17522 15.7071C7.36275 15.8946 7.61711 16 7.88232 16ZM17.8823 4H13.8823V3C13.8823 2.20435 13.5663 1.44129 13.0036 0.87868C12.441 0.316071 11.678 0 10.8823 0H8.88232C8.08667 0 7.32361 0.316071 6.761 0.87868C6.19839 1.44129 5.88232 2.20435 5.88232 3V4H1.88232C1.61711 4 1.36275 4.10536 1.17522 4.29289C0.987681 4.48043 0.882324 4.73478 0.882324 5C0.882324 5.26522 0.987681 5.51957 1.17522 5.70711C1.36275 5.89464 1.61711 6 1.88232 6H2.88232V17C2.88232 17.7956 3.19839 18.5587 3.761 19.1213C4.32361 19.6839 5.08667 20 5.88232 20H13.8823C14.678 20 15.441 19.6839 16.0036 19.1213C16.5663 18.5587 16.8823 17.7956 16.8823 17V6H17.8823C18.1475 6 18.4019 5.89464 18.5894 5.70711C18.777 5.51957 18.8823 5.26522 18.8823 5C18.8823 4.73478 18.777 4.48043 18.5894 4.29289C18.4019 4.10536 18.1475 4 17.8823 4ZM7.88232 3C7.88232 2.73478 7.98768 2.48043 8.17522 2.29289C8.36275 2.10536 8.61711 2 8.88232 2H10.8823C11.1475 2 11.4019 2.10536 11.5894 2.29289C11.777 2.48043 11.8823 2.73478 11.8823 3V4H7.88232V3ZM14.8823 17C14.8823 17.2652 14.777 17.5196 14.5894 17.7071C14.4019 17.8946 14.1475 18 13.8823 18H5.88232C5.61711 18 5.36275 17.8946 5.17522 17.7071C4.98768 17.5196 4.88232 17.2652 4.88232 17V6H14.8823V17ZM11.8823 16C12.1475 16 12.4019 15.8946 12.5894 15.7071C12.777 15.5196 12.8823 15.2652 12.8823 15V9C12.8823 8.73478 12.777 8.48043 12.5894 8.29289C12.4019 8.10536 12.1475 8 11.8823 8C11.6171 8 11.3628 8.10536 11.1752 8.29289C10.9877 8.48043 10.8823 8.73478 10.8823 9V15C10.8823 15.2652 10.9877 15.5196 11.1752 15.7071C11.3628 15.8946 11.6171 16 11.8823 16Z"
                  fill="#878b9d" />
          </svg>`,
          content:
            'Vill du ta bort hela ansökan och dess information, från tjänsten helt och hållet?',
          cta: 'Vill du radera ansökan?',
          type: 'REMOVE'
        }
      ],
      selectedPartInfo: null,
      selectedApplicantId: null,
      selectedDocumentType: null,
      selectedDocumentCategoryType: null,
      userHistory: [],
      ovReasonText,
      readableStatusText,
      statusTypes,
      rejectReasonModalVisible: true
      //validDate
    };
  },
  computed: {
    ...mapState({
      ovApps: state => state.applications.ovApps,
      landlordUsersWithUnAssign: state => state.applications.landlordUsers,
      activeApp: state => state.app.activeApp
    }),
    ...mapGetters({
      ovArchivedStatuses: 'applications/ovArchivedStatuses',
      ovPendingStatuses: 'applications/ovPendingStatuses',
      activeApplicationType: 'app/activeApplicationType'
    }),
    showRejectReasonModal() {
      return this.isRejected && this.rejectReasonModalVisible;
    },
    landlordRejectionNote() {
      return this.application?.landlordRejectionNote;
    },
    activeTabs() {
      return [
        {
          title: 'Överlåtelsen',
          active: this.activeTab === 1,
          tabLink: 1
        },
        {
          title: `<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fill="#fff"
              d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
            ></path>
          </svg>Historik`,
          active: this.activeTab === 2,
          tabLink: 2
        }
      ];
    },
    secondaryTenants() {
      return this.application.secondaryTenants;
    },
    currentTenants() {
      return this.application.currentTenants;
    },
    isRemoved() {
      return this.application.landlordStatus === this.statusTypes.REMOVED;
    },
    isPendingRemoval() {
      return (
        this.application.landlordStatus === this.statusTypes.PENDING_REMOVE
      );
    },
    hasRemovalTypeStatus() {
      return this.isRemoved || this.isPendingRemoval;
    },
    applicantName() {
      if (this.selectedDocumentCategoryType === 'transferee') {
        return this.application.secondaryTenants[0].name;
      } else if (this.selectedDocumentCategoryType === 'transferor') {
        return this.application.currentTenants[0].name;
      }
      return '';
    },
    currentApplicationId() {
      const { id } = this.$route.params;
      return Number(id);
    },
    activeTab() {
      return this.$route.query.tab ? parseInt(this.$route.query.tab) : 1;
    },
    getReasonText() {
      return this.ovReasonText(this.application.reason);
    },
    tabHeaderSubtitle() {
      return this.readableStatusText(this.application.landlordStatus) || '';
    },
    isArchived() {
      return this.ovArchivedStatuses.includes(this.application.landlordStatus);
    },
    isIncoming() {
      return this.application.landlordStatus === this.statusTypes.INCOMING;
    },
    isRejected() {
      return this.application.landlordStatus === this.statusTypes.REJECTED;
    },
    otherCheckboxes() {
      const transferDate = this.application.reason?.transferDate;
      return [
        {
          category: 'onskat_ov_datum',
          value: validDate(transferDate)
            ? new Date(transferDate).toLocaleDateString('sv-SE')
            : '-'
        }
      ];
    },

    isCompanyApplication() {
      return this.application.applicationType === 'ov-company';
    }
  },
  watch: {
    currentApplicationId: {
      async handler(newId, prevId) {
        if (newId !== prevId) {
          this.isLoading = true;
          try {
            await this.getOvApplication(this.activeApplicationType);
            await this.getFiles(this.currentApplicationId);
            await this.getuserHistory();
          } catch {
            this.showErrorModal();
          }
          this.isLoading = false;
        }
      },
      immediate: true
    },
    activeTab: {
      async handler(activeTab) {
        if (activeTab === 2 && this.application) {
          await this.getuserHistory();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      setOvApplication: 'applications/setOvApplication'
    }),
    ...mapActions({
      getFiles: 'landlordFiles/getFiles'
    }),

    getToPath(id) {
      const currentPath = this.$router.currentRoute.path;

      const applicationId = Number(id);

      // Here we try to find the correct path
      // We don't fetch all apps initally depending on which view is loaded
      // Initially until the other view is loaded we have to make assumptions
      // If we cant find the app we assume it's on the other view

      if (currentPath.includes(`${APP_NAMES.OVERLATELSER_PRIVATE}/alla`)) {
        if (
          this.ovApps.find(
            app =>
              app.id === applicationId &&
              this.ovPendingStatuses.includes(app.landlordStatus)
          )
        ) {
          return `/${APP_NAMES.OVERLATELSER_PRIVATE}/alla`;
        } else {
          return `/${APP_NAMES.OVERLATELSER_PRIVATE}/arkiv`;
        }
      }

      if (currentPath.includes(`${APP_NAMES.OVERLATELSER_PRIVATE}/arkiv`)) {
        if (
          this.ovApps.find(
            app =>
              app.id === applicationId &&
              this.ovArchivedStatuses.includes(app.landlordStatus)
          )
        ) {
          return `/${APP_NAMES.OVERLATELSER_PRIVATE}/arkiv`;
        } else {
          return `/${APP_NAMES.OVERLATELSER_PRIVATE}/alla`;
        }
      }

      if (currentPath.includes(`${APP_NAMES.OVERLATELSER_COMPANY}/alla`)) {
        if (
          this.ovApps.find(
            app =>
              app.id === applicationId &&
              this.ovPendingStatuses.includes(app.landlordStatus)
          )
        ) {
          return `/${APP_NAMES.OVERLATELSER_COMPANY}/alla`;
        } else {
          return `/${APP_NAMES.OVERLATELSER_COMPANY}/arkiv`;
        }
      }

      if (currentPath.includes(`${APP_NAMES.OVERLATELSER_COMPANY}/arkiv`)) {
        if (
          this.ovApps.find(
            app =>
              app.id === applicationId &&
              this.ovArchivedStatuses.includes(app.landlordStatus)
          )
        ) {
          return `/${APP_NAMES.OVERLATELSER_COMPANY}/arkiv`;
        } else {
          return `/${APP_NAMES.OVERLATELSER_COMPANY}/alla`;
        }
      }

      // Fallback
      return `/${APP_NAMES.OVERLATELSER_PRIVATE}/alla`;
    },
    async getuserHistory() {
      const userApps = await this.$http.getUserOvApplications(
        this.application.userId
      );
      this.userHistory = userApps;
    },
    async assignLandlordUser({ firstName, id, lastName }) {
      if (id === null) {
        this.unassignLandlordUser();
        return;
      }

      this.application.assignedLandlordUserId = id;
      this.application.assignedLandlordFirstName = firstName;
      this.application.assignedLandlordLastName = lastName;

      await this.$http.assignLandlordUser(this.currentApplicationId, id, true);
      // churnService.track(events.UPDATE_MANAGER, features.UPDATE_MANAGER.ASSIGN);

      this.setOvApplication({
        id: this.currentApplicationId,
        assignedLandlordUserId: id,
        assignedLandlordFirstName: firstName,
        assignedLandlordLastName: lastName
      });
    },

    async unassignLandlordUser() {
      this.application.assignedLandlordUserId = null;
      this.application.assignedLandlordFirstName = null;
      this.application.assignedLandlordLastName = null;

      await this.$http.unassignLandlordUser(this.currentApplicationId, true);
      // churnService.track(
      //   events.UPDATE_MANAGER,
      //   features.UPDATE_MANAGER.UNASSIGN
      // );

      this.setOvApplication({
        id: this.currentApplicationId,
        assignedLandlordUserId: null,
        assignedLandlordFirstName: null,
        assignedLandlordLastName: null
      });
    },
    leaveApplication() {
      if (this.activeApp === APP_NAMES.OVERLATELSER_COMPANY) {
        this.$router.push({ path: `/${APP_PATHS.OVERLATELSER_COMPANY}/alla` });
      } else {
        this.$router.push({ path: `/${APP_PATHS.OVERLATELSER_COMPANY}/alla` });
      }
    },
    async updateCheckedMember({ checked, category, categoryType }) {
      try {
        if (checked) {
          await this.$http.addCheck(this.application.id, 0, category);
          const addedChecks = [...this.application.checkedFiles, category];

          this.$set(this.application, 'checkedFiles', addedChecks);
        } else {
          await this.$http.removeCheck(this.application.id, 0, category);
          const removedChecks = this.application.checkedFiles.filter(
            check => check !== category
          );

          this.$set(this.application, 'checkedFiles', removedChecks);
        }
      } catch (error) {
        console.error(error);
      }
    },
    openDocumentModal({ index, category, categoryType }) {
      if (this.isIncoming) {
        return;
      } else {
        this.selectedApplicantId = `${index}`;
        this.selectedDocumentType = category;
        this.selectedDocumentCategoryType = categoryType;
        this.currentModal = 'DOCUMENTS';
      }
    },
    showErrorModal() {
      this.errorModalVisible = true;
    },
    handleEditNote({ editedText, noteId }) {
      const noteIndex = this.application.landlordNotes.findIndex(
        n => n.id === noteId
      );

      if (noteIndex !== -1) {
        this.$set(this.application.landlordNotes, noteIndex, {
          ...this.application.landlordNotes[noteIndex],
          text: editedText,
          editedAt: true
        });
      }
    },
    handleDeleteNote(noteId) {
      const noteIndex = this.application.landlordNotes.findIndex(
        note => note.id === noteId
      );
      if (noteIndex !== -1) {
        this.$set(this.application.landlordNotes, noteIndex, {
          ...this.application.landlordNotes[noteIndex],
          deletedAt: true
        });
      }
    },
    handleAddNote(note) {
      this.application.landlordNotes = [
        ...(this.application.landlordNotes || []),
        note
      ];
    },
    async getOvApplication(applicationType) {
      const res = await this.$http.getOvApplication(
        this.currentApplicationId,
        applicationType
      );
      this.application = res;
    },
    setTab(tabNumber) {
      this.$router.push({ query: { tab: tabNumber } });
    },
    handleEscClick() {
      if (!this.currentModal) {
        this.$emit('close');
      }
    },
    handlePopupClick(type) {
      switch (type) {
        case 'REMOVE':
          if (this.currentUserIsAssigned) {
            this.currentModal = 'REMOVE';
          } else {
            this.currentModal = 'USER_NOT_ASSIGNED';
          }
          break;
        case 'ABORT':
          console.log('Not implemented: Show ABORT modal');
          break;
        default:
          this.currentModal = null;
      }
      this.popupModalVisible = false;
    }
  }
};
</script>

<style scoped>
.name {
  color: var(--color-dark-blue);
  font-weight: 600;
  font-size: 1.8rem;
}
.name-small {
  color: var(--color-dark-blue);
}
h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.5rem;
}
.apartment-info li {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
}
.apartment-info li b {
  font-weight: 600;
}

.divider {
  background-color: #e4e5e8;
  margin: 2rem 0;
  border: none;
  height: 1px;
}

.base-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: hidden;
}
.body {
  position: relative;
  background: #fff;
  padding: 25px 35px 50px 35px;
  width: 100%;
  overflow: auto;
  max-height: 70vh;
}
.body > :deep(.applications-row) {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.body--animating {
  overflow: hidden;
}
.body--animating > :deep(.applications-row) {
  opacity: 0;
  transition: none;
}
.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  padding: 22px 35px 22px 35px;
  background: #f1f2f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.footer > .left {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
}
::v-deep .left .text {
  margin-left: 7px;
}
.footer > .left > * {
  margin-right: 25px;
}
.mr-15 {
  margin-right: 15px;
}
.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.pdf-link {
  margin-top: 30px;
  font-size: 12px;
  text-decoration: underline;
}
.assign-button,
.notes-button,
.files-button {
  font-weight: 600;
  font-size: 1.2rem;
}
.assign-button {
  color: var(--color-dark-blue);
  font-weight: 700;
}
.popupModal-container {
  position: relative;
  vertical-align: text-bottom;
  display: inline-block;
}

.link-chain {
  background-color: #fff;
  padding: 0.4rem;
  width: 3.8rem;
  height: 3.8rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 3rem;
}

.application-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 260px;
}

.is-same-card {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  padding: 3px;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  font-family: 'Averta Std';
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.is-same-card svg {
  transform: rotate(180deg);
  width: 1.4rem;
  height: 1.4rem;
}

.reject-reason {
  font-size: 1.8rem;
  font-weight: 600;
  width: 500px;
  max-width: 100%;
}

.reject-reason .explanation {
  color: var(--color-dark-blue);
}

.application-card.memberOneSelected {
  border-top-left-radius: 0;
}

.grey-box {
  background: #f8f8fa;
  padding: 2.2rem;
  border-radius: 10px;
  color: var(--color-dark-blue);
}
</style>
