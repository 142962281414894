<template>
  <DefaultPage>
    <FilterMain
      v-model="filterQuery"
      :value="filterQuery"
      @input="debouncedSearch"
      @clear="clearInputSearch"
    />

    <div class="applications-view-wrapper">
      <div v-if="isLoading" class="loading-wrapper">
        <BaseLoadingSpinner :with-overlay="false" :size="60" />
      </div>

      <section v-else class="applications-wrapper">
        <div v-if="filteredRemovedApps" class="lane-wrapper">
          <div class="sub-header">
            <h2 class="lane-title">
              Arkiverade byten
              <span
                v-if="filteredRemovedApps.length > 0"
                class="lane-title-note"
                ><span class="lane-title-note-number">{{
                  filteredRemovedApps.length
                }}</span></span
              >
            </h2>
          </div>

          <div class="applications-container">
            <router-link
              v-for="application in filteredRemovedApps"
              :key="application.id"
              :to="`/byten/arkiv/${application.id}`"
              class="application-wrapper"
              :class="{
                approved: application.status === statusTypes.APPROVED,
                rejected: application.status === statusTypes.REJECTED,
                new: cardIsNew(application)
              }"
            >
              <BaseImage
                v-if="isFromLb(application)"
                :key="`${application.id}-lb`"
                :url="svgs.ICONS.LB_LOGO"
                class="lb-icon"
              ></BaseImage>
              <div>
                <h3 class="application-title">
                  {{ application.type }}
                </h3>

                <ul class="info-wrapper">
                  <template v-for="applicant of application.parts">
                    <li
                      v-for="(location, i) of applicant"
                      :key="`${location.id}-${i}`"
                      class="address"
                    >
                      <div class="address-text">
                        <span class="light">
                          {{ location.address || '(Adress saknas)' }}</span
                        >
                        <p class="landlord">
                          ({{
                            location.landlord.name.length > 0
                              ? location.landlord.name
                              : 'Hyresvärd ej angivet'
                          }})
                        </p>
                      </div>
                      <BaseImage
                        v-if="location.members[0]?.comments.length"
                        :title="`${memberNameOrFallback(
                          location.members[0]
                        )} har en eller flera kommentarer`"
                        :url="svgs.ICONS.COMMENT"
                        class="has-comment-icon"
                      ></BaseImage>
                    </li>
                  </template>
                </ul>
              </div>

              <section class="application-footer light">
                <div class="application-footer-info">
                  <p
                    v-if="
                      getStatusDate(
                        application.status,
                        application.statusUpdated,
                        application.sentToHost
                      )
                    "
                  >
                    {{ getStatusText(application.status) }}
                    <b>
                      {{
                        getStatusDate(
                          application.status,
                          application.statusUpdated,
                          application.sentToHost
                        )
                      }}
                    </b>
                  </p>
                </div>
                <UserIcon
                  class="user-icon"
                  :first-name="fullName(application)"
                  :last-name="lastName(application)"
                  :show-icon="!fullName(application) && !lastName(application)"
                >
                  <NotificationSymbol v-if="application?.unreadNotes > 0" />
                </UserIcon>
              </section>
            </router-link>
          </div>
        </div>
      </section>
      <router-view />
    </div>
  </DefaultPage>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';

import statusTypes from '@/utils/statusTypes';
import FilterMain from '@/components/FilterMain.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import UserIcon from '@/components/UserIcon.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';
import NotificationSymbol from '@/components/NotificationSymbol.vue';
import DefaultPage from '@/components/DefaultPage.vue';

export default {
  name: 'ArchiveView',
  components: {
    FilterMain,
    BaseLoadingSpinner,
    UserIcon,
    BaseImage,
    NotificationSymbol,
    DefaultPage
  },
  beforeRouteUpdate(to, from, next) {
    this.maybeClearInputSearch(to.path);
    next();
  },
  beforeRouteLeave(to, from, next) {
    //Leaving to new route and unmounting
    this.maybeClearInputSearch(to.path);
    next();
  },

  data() {
    return {
      filterQuery: '',
      statusTypes,
      svgs,
      isLoading: false
    };
  },

  computed: {
    ...mapGetters({
      tooltips: 'app/tooltips',
      filteredRemovedApps: 'applications/filteredRemovedApps'
    }),
    closedStatuses() {
      return [statusTypes.CLOSED, statusTypes.REJECTED, statusTypes.APPROVED];
    }
  },
  beforeDestroy() {
    this.clearFilter();
  },
  created() {
    this.debouncedSearch = debounce(this.search, 300);
    this.loadApplications();
  },

  methods: {
    ...mapActions({
      fetchRemovedApps: 'applications/fetchRemovedApps',
      clearFilter: 'applications/clearFilter'
    }),
    ...mapMutations({
      setFilter: 'applications/setFilter',
      resetFirstPartOfTooltipsArray: 'app/resetFirstPartOfTooltipsArray'
    }),
    getStatusDate(status, statusUpdated, sentToHost) {
      if (this.closedStatuses.includes(status)) {
        return statusUpdated ? statusUpdated.substring(0, 10) : '';
      }

      return sentToHost
        ? new Date(Number(sentToHost)).toISOString().slice(0, 10)
        : '';
    },
    getStatusText(status) {
      if (this.closedStatuses.includes(status)) {
        return 'Beslut taget:';
      }

      return 'Färdig för beslut:';
    },
    isFromLb(application) {
      return !!application.parts.find(part => part.find(x => x.propositionId));
    },
    fullName(application) {
      return application.assignedLandlordUser?.firstName;
    },
    lastName(application) {
      return application.assignedLandlordUser?.lastName;
    },
    cardIsNew(application) {
      return application.status === statusTypes.READY && application.isNew;
    },
    maybeClearInputSearch(path) {
      if (!path.includes('byten/arkiv')) {
        this.clearInputSearch();
      }
    },
    clearInputSearch() {
      this.filterQuery = '';
      this.debouncedSearch.cancel();
      this.search('');
    },
    async loadApplications() {
      this.isLoading = true;
      try {
        await this.fetchRemovedApps();
      } catch {
        // catch
      }
      this.isLoading = false;
    },
    search(query) {
      this.setFilter(query);
    },
    memberNameOrFallback(member) {
      return member?.name ? member?.name : '(Namn saknas)';
    }
  }
};
</script>

<style scoped>
.applications-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  max-width: 1080px;
}

.applications-view-wrapper {
  flex: 1;
  margin-left: 20px;
}

.loading-wrapper {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.lane-title-note {
  margin-left: 10px;
  border-radius: 12px;
  display: inline-flex;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
  min-width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  justify-content: center;
  margin-top: -2px;
  padding: 8px;
}

.lane-title-note .lane-title-note-number {
  font-family: Arial, Helvetica, sans-serif;
}

.lane-title {
  white-space: nowrap;
  font-weight: 600;
  font-size: 1.8rem;
  font-family: var(--font-poppins);
  display: flex;
  align-items: center;
}

.lane-wrapper {
  padding-top: 2rem;
  flex: 1 1 auto;
}

.application-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-right: 3rem;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border: 3px solid transparent;
  cursor: pointer;
  max-width: 310px;
}

.application-wrapper:nth-child(3n) {
  margin-right: 0;
}

@media (min-width: 1100px) {
  .application-wrapper {
    transition: 0.2s ease-in-out;
    transform: scale(1);
  }

  .application-wrapper:hover {
    transform: scale(1.02307692307);
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  }
}

.address {
  color: var(--color-dark-blue);
  font-size: clamp(1.2rem, 0.7vw, 1.4rem);
  font-weight: 600;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: flex-start;
}

.address-text {
  flex: 1 1 auto;
}

.light {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 600;
}

.application-title {
  font-size: clamp(1.4rem, 0.8vw, 1.6rem);
  font-weight: 600;
  color: var(--color-dark-blue);
  margin-bottom: 0.7rem;
}

.application-footer {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  color: #878b9d;
}

.applications-container {
  position: relative;
  padding: 1rem 2rem 0;
  max-height: calc(100vh - 190px);
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

::-webkit-scrollbar-track {
  background: none;
}

.lb-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
}

.sub-header {
  min-width: 24rem;
  padding: 0 2rem 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.landlord {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 400;
}

.application-footer-info {
  margin-top: 1rem;
}

.application-footer-info p {
  color: #878b9d;
  font-weight: 400;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  backface-visibility: hidden;
}

.application-footer-info p b {
  font-weight: 600;
}

.user-icon {
  align-self: flex-end;
}

.info-wrapper {
  padding-right: 2.4rem;
  backface-visibility: hidden;
}

.has-comment-icon {
  height: 2rem;
  width: 2rem;
  background-size: contain;
  margin-right: 1rem;
}
</style>
