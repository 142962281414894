<template>
  <div class="application">
    <slot name="top-content" />
    <GreyCard
      class="application-card"
      :class="{ 'flex-row': flex, dark: darkBg }"
    >
      <div class="card-content">
        <slot />
      </div>
    </GreyCard>
  </div>
</template>

<script>
import GreyCard from '@/components/GreyCard.vue';
export default {
  components: {
    GreyCard
  },
  props: {
    flex: {
      type: Boolean,
      default: false
    },
    darkBg: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.application {
  width: 320px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}
.application-card {
  width: 100%;
  min-height: 300px;
  margin-top: 0.5rem;
}
.application-card.flex-row {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(2, 1fr);
}

.card-content {
  line-height: 1.6;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.card-content:nth-last-child(1) {
  margin-bottom: 0;
}
.card-content > h3 {
  font-weight: 700;
  margin-bottom: 5px;
}
.card-content > h4 {
  line-height: 1.3;
}
.card-content > p {
  font-weight: 400;
}
.dark {
  background-color: var(--member-two-color);
}
</style>
