<template>
  <BaseModal v-bind="$attrs" v-on="$listeners">
    <div ref="modalElement" class="applicant-info-wrapper">
      <div
        v-for="person in selectedInfo"
        :key="person.index"
        class="member-wrapper"
      >
        <h3 class="name">
          {{ person.name }}
          <span v-if="person.isRenter" class="contract"
            >(Kontraktsinnehavaren)</span
          >
          <span v-else-if="person.isRenter === false" class="contract"
            >(Medhyresgäst)</span
          >
        </h3>
        <span class="address">{{
          `${person.address}${person.city ? `, ${person.city}` : ''}`
        }}</span>
        <span class="email">
          <BaseIconText :icon-url="svgs.ICONS.ENVELOPE">
            {{ person.email || '-' }}
          </BaseIconText>
        </span>
        <span class="ssn">
          <BaseIconText :icon-url="svgs.ICONS.ADMINISTRATOR_GRAY">
            {{ person.ssn || '-' }}
          </BaseIconText>
        </span>
        <span class="phone">
          <BaseIconText :icon-url="svgs.ICONS.PHONE">
            {{ person.phone || '-' }}
          </BaseIconText>
        </span>
      </div>

      <!-- <span v-if="selectedPartInfo.propositionId" class="lb-connection">
        <BaseIconText :icon-url="svgs.ICONS.LB_LOGO" size="larger">
          <p>Bytesparten kommer från Lägenhetsbyte.se</p>
          <a
            href="https://lagenhetsbyte.se/preview"
            @click.stop.prevent="generatePreviewLink()"
          >
            Visa annons på Lägenhetsbyte.se
          </a>
        </BaseIconText>
      </span> -->
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import svgs from '@/utils/icon-constants';

export default {
  name: 'OvPartInfoModal',
  components: {
    BaseModal,
    BaseIconText
  },

  props: {
    selectedInfo: {
      type: Array,
      required: true
    },
    position: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      svgs
    };
  },

  async mounted() {
    if (!this.position) {
      return;
    }

    let left = this.position.x - this.$refs.modalElement.clientWidth / 2;
    if (left < 0) {
      left = 0;
    }

    if (left + this.$refs.modalElement.clientWidth / 2 > window.innerWidth) {
      left = window.innerWidth - this.$refs.modalElement.clientWidth;
    }

    this.$refs.modalElement.style.top = `${this.position.y}px`;
    this.$refs.modalElement.style.left = `${left}px`;
  }
};
</script>

<style scoped>
.member-wrapper {
  margin-bottom: 20px;
}

.applicant-info-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: white;
  min-width: 500px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  padding: 23px 35px;
  font-weight: 600;
  font-size: 14px;
  position: fixed;

  font-feature-settings: 'liga' off;
  line-height: 17px;
  color: #878b9d;
}

.applicant-info-wrapper .name {
  color: var(--color-dark-blue);
  font-size: 1.8rem;
  font-weight: 600;
}

.applicant-info-wrapper .name .contract {
  color: var(--color-blue);
}

.applicant-info-wrapper .address {
  display: block;
  margin-top: 5px;
}

.applicant-info-wrapper .email,
.applicant-info-wrapper .ssn,
.applicant-info-wrapper .phone {
  display: block;
  margin-top: 1.5rem;
}

.applicant-info-wrapper .ssn :deep(.icon) {
  background-size: contain;
}

.applicant-info-wrapper .lb-connection {
  display: block;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #e8e8e8;
  line-height: 20px;
}

.applicant-info-wrapper .lb-connection a {
  color: var(--color-blue);
  text-decoration: underline;
  font-weight: 600;
}

.applicant-info-wrapper .lb-connection :deep(.text) {
  display: block;
}
</style>
