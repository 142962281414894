import { render, staticRenderFns } from "./OvApplicationsLane.vue?vue&type=template&id=479ec975&scoped=true"
import script from "./OvApplicationsLane.vue?vue&type=script&lang=js"
export * from "./OvApplicationsLane.vue?vue&type=script&lang=js"
import style0 from "./OvApplicationsLane.vue?vue&type=style&index=0&id=479ec975&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479ec975",
  null
  
)

export default component.exports