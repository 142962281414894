function ovReasonText(reason) {
  switch (reason.type) {
    case 'halsa':
      return 'Hälsoskäl';
    case 'separation':
      return 'Separation/skilsmässa';
    case 'dodsfall':
      return 'Dödsfall';
    case 'ekonomi':
      return 'Ekonomiska skäl';
    case 'aldreboende':
      return 'Flytt till äldreboende';
    case 'student':
      return 'Studentlägenhet';
    case 'annat':
      return 'Annat';
    default:
      return reason.type;
  }
}

export { ovReasonText };
