<template>
  <div>
    <ul class="apartment-info">
      <li><b>Org.nr:</b> {{ company.organizationNumber }}</li>
      <li v-if="company.ceo"><b>VD:</b> {{ company.ceo }}</li>
      <li v-else-if="company.chairman">
        <b>Ordförande:</b> {{ company.chairman }}
      </li>
      <li v-else-if="company.boardMember">
        <b>Ledamot:</b> {{ company.boardMember }}
      </li>
      <li>
        <b>SNI-branch:</b>
        {{ company.sni }}
      </li>
      <li>
        <b>Registreringsdatum:</b>
        {{ new Date(company.registrationDate).toLocaleDateString('sv-SE') }}
      </li>
      <li v-if="company.employeesAmount">
        <b>Antal anställda:</b>
        {{ company.employeesAmount }}
      </li>
      <li>
        <BaseIconText
          class="icon-holder"
          size="smaller"
          :icon-url="getIcon(company.moms)"
        >
          Moms
        </BaseIconText>
      </li>
      <li>
        <BaseIconText
          class="icon-holder"
          size="smaller"
          :icon-url="getIcon(company.fSkatt)"
        >
          F-skatt
        </BaseIconText>
      </li>
      <li>
        <BaseIconText
          class="icon-holder"
          size="smaller"
          :icon-url="getIcon(company.arbetsgivaravgift)"
        >
          Arbetsgivaravgift
        </BaseIconText>
      </li>
    </ul>
    <h4>
      <b>Resultaträkning {{ firstAccounting.year }}</b>
    </h4>
    <ul class="apartment-info">
      <li><b>Omsättning:</b> {{ firstAccounting.revenue }} kr</li>
      <li><b>Resultat:</b> {{ firstAccounting.netProfit }} kr</li>
    </ul>
  </div>
</template>
<script>
import svgs from '@/utils/icon-constants';

import BaseIconText from '@/components/BaseIconText.vue';

export default {
  components: {
    BaseIconText
  },
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    sortedAccounting() {
      if (!this.company?.accounting?.length) {
        return [];
      }
      return [...this.company.accounting].sort((a, b) => {
        return b.year.localeCompare(a.year);
      });
    },
    firstAccounting() {
      if (!this.sortedAccounting?.length) {
        return {};
      }
      return this.sortedAccounting[0];
    }
  },
  methods: {
    getIcon(val) {
      return val ? this.svgs.ICONS.CHECK_GREEN : this.svgs.ICONS.ERROR_RED;
    }
  }
};
</script>

<style scoped>
h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.5rem;
}
ul li {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
}
ul li b {
  font-weight: 600;
}
.icon-holder {
  margin: 0.5rem 0;
}
</style>
