<template>
  <ErrorModal v-if="hasError" @close="$emit('close')" />
  <DefaultModal v-else @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Godkänn ansökan?</DefaultModalTitle>
    </template>

    <template slot="body">
      <div :style="{ textAlign: 'center' }">
        <p class="swap-description">
          <span v-if="!dateChanged">Godkänn överlåtelsedatum:</span>
          <span v-else class="rentchangetitle">Godkänn med nytt datum:</span>
        </p>
        <div class="date-container">
          <div class="datePeriod">
            <Input
              v-model="actualTransferDateProxy"
              :class="{ hasChanged: dateChanged }"
              label="Överlåtelsedatum"
              type="date"
              :min="new Date().toISOString().split('T')[0]"
            />
          </div>
        </div>
        <p v-if="dateChanged" class="wish-period">
          Önskat datum:
          <br />
          <b>
            <span :class="{ hasChanged: dateChanged }">{{
              transferDateProxy
            }}</span></b
          >
        </p>
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        :is-loading="isSaving"
        :is-rounded="true"
        :icon="svgs.ICONS.CHECK_WHITE"
        @click="approveOvApplication"
        >Godkänn ansökan</BaseButton
      >
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import { mapActions } from 'vuex';
import Input from '@/components/DateInput.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import ErrorModal from '@/components/ErrorModal.vue';
// import churnService from '@/utils/churn360';
// import { events, features } from '@/utils/trackingEvents';

export default {
  name: 'OvApprovalModal',
  components: {
    DefaultModal,
    BaseButton,
    Input,
    DefaultModalTitle,
    ErrorModal
  },

  props: {
    applicationId: {
      type: Number,
      default: null
    },
    //Actual confirmed date by hyresvärd
    initialTransferDate: {
      type: String,
      default: null
    },
    //Date suggested by överlåtaren
    transferDate: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false,
      actualTransferDate: this.initialTransferDate,
      hasError: false
    };
  },
  computed: {
    dateChanged() {
      return this.actualTransferDateProxy !== this.transferDateProxy;
    },
    transferDateProxy() {
      return this.getDate(this.transferDate);
    },
    actualTransferDateProxy: {
      get() {
        return this.actualTransferDate
          ? this.getDate(this.actualTransferDate)
          : this.transferDateProxy;
      },
      set(value) {
        this.actualTransferDate = value;
      }
    }
  },
  methods: {
    ...mapActions({
      approveOvApp: 'applications/approveOvApp',
      setOvTransferDate: 'applications/setOvTransferDate'
    }),
    async approveOvApplication() {
      this.isSaving = true;
      this.hasError = false;
      // churnService.track(
      //   events.CHANGE_SWAP_STATUS,
      //   features.CHANGE_SWAP_STATUS.APPROVE
      // );
      try {
        await this.setOvTransferDate({
          id: this.applicationId,
          actualTransferDate: this.actualTransferDateProxy
        });
        await this.approveOvApp({ id: this.applicationId });
        this.$emit('onApproved');
      } catch {
        this.hasError = true;
      }
      this.isSaving = false;
    },
    getDate(value) {
      return value ? this.sweDateStr(value) : '';
    },
    sweDateStr(value = null) {
      return new Date(value).toLocaleDateString('sv-SE');
    }
  }
};
</script>

<style scoped>
.swap-description {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}
.swap-description b {
  color: var(--color-dark-blue);
}
.wish-period {
  font-size: 14px;
  padding: 8px;
}
.wish-period.no-padding {
  padding: 0;
}
.new {
  color: #40c07b;
}
.rentchangetitle {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}
.datePeriod {
  position: relative;
  display: inline-block;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.datePeriod > * {
  flex: 1 1 auto;
}
.line {
  height: 2px;
  width: 30px;
  background-color: var(--color-dark-blue);
}
.hasChanged {
  color: var(--color-blue);
}
.hasChanged :deep(input) {
  border-color: var(--color-blue);
}
.red {
  color: var(--color-red);
}
</style>
