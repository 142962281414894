export const APP_NAMES = {
  ANDRAHAND: 'andrahand',
  BYTESANSOKAN: 'bytesansokan',
  OVERLATELSER_PRIVATE: 'overlatelser-privat',
  OVERLATELSER_COMPANY: 'overlatelser-foretag'
};
export const APP_PATHS = {
  ANDRAHAND: 'andrahand',
  BYTESANSOKAN: 'byten',
  OVERLATELSER_PRIVATE: 'overlatelser-privat',
  OVERLATELSER_COMPANY: 'overlatelser-foretag'
};

export default {
  APP_NAMES,
  APP_PATHS
};
