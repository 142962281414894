<template>
  <div class="member-selector">
    <button
      v-for="(item, index) in items"
      :key="index"
      :class="{
        selector: true,
        selected: index === activeIndex
      }"
      @click="$emit('selectItemIndex', index)"
    >
      {{ item.label }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'MemberSelector',
  props: {
    items: {
      type: Array,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    }
  }
};
</script>
<style scoped>
.member-selector {
  display: flex;
  margin-top: 0.5rem;
}
.member-selector .selector {
  box-shadow: none;
  border: none;
  padding: 10px 8px 17px;
  font-weight: bold;
  color: #222;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  margin-bottom: -12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex: 1;
  flex-basis: 0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.member-selector .selector {
  background-color: var(--member-one-color);
}

.member-selector .selector:nth-child(even) {
  background-color: var(--member-two-color);
}
</style>
