<template>
  <DefaultModal class="subletinfo-modal-wrapper" @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Företagsöverlåtelser</DefaultModalTitle>
    </template>

    <template slot="body">
      <div class="body-wrapper">
        <section class="body-section">
          <h2 class="title">
            Kul att du är intresserad av Överlåtelser för företag!
          </h2>

          <section class="modal-bullets-container">
            <p class="contact">
              Kontakta Morgan för att komma igång:
              <br />
              <a href="mailto:morgan@lagenhetsbyte.se?subject=Bytesansökan"
                >morgan@lagenhetsbyte.se</a
              >
              <br />
              070-815 61 74
            </p>
          </section>
        </section>
      </div>
    </template>

    <template slot="footer">
      <BaseButton :is-rounded="true" :is-default="true" @click="$emit('close')"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import outsideClick from '@/directives/outside-click';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'SubletInfoModal',
  directives: { outsideClick },
  components: {
    DefaultModal,
    DefaultModalTitle,
    BaseButton
  }
};
</script>

<style scoped>
.subletinfo-modal-wrapper :deep(.footer) {
  padding: 15px 35px;
}
.body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  width: 450px;
}
.body-wrapper .body-section .title {
  width: 100%;
  font-size: 1.8rem;
  padding: 0 0 0.8rem 0;
}
.body-wrapper .body-section .modal-bullets-container {
  width: 100%;
  font-size: 1.4rem;
}
.body-wrapper .body-section .modal-bullets-container .contact {
  font-size: 1.6rem;
}
.body-wrapper .body-section .modal-bullets-container a {
  text-decoration: none;
  color: var(--color-blue);
}
.body-wrapper .body-section .modal-bullets-container a:hover {
  text-decoration: underline;
}
</style>
