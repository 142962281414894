<template>
  <div v-if="applications" class="lane-wrapper">
    <div class="sub-header">
      <h2 class="lane-title" :class="[statusTextClass]">
        {{ title }}
        <span
          v-if="applications.length > 0"
          class="lane-title-note"
          :class="[statusBgClass]"
          ><span class="lane-title-note-number">{{
            applications.length
          }}</span></span
        >
      </h2>
      <slot name="controls" />
    </div>

    <div class="applications-container" :style="containerStyle">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationsLane',

  props: {
    title: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    containerStyle: {
      type: Object,
      default: null
    },
    applications: {
      type: [Array, null],
      default: null
    }
  },

  computed: {
    statusBgClass() {
      if (this.status) return 'bg-' + this.status;
      return '';
    },
    statusTextClass() {
      if (this.status) return 'text-' + this.status;
      return '';
    }
  }
};
</script>

<style scoped>
.lane-title-note {
  margin-left: 10px;
  border-radius: 12px;
  display: inline-flex;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
  min-width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  justify-content: center;
  margin-top: -2px;
  padding: 8px;
  background-color: var(--color-grey);
}

.lane-title-note .lane-title-note-number {
  font-family: Arial, Helvetica, sans-serif;
}

.lane-title {
  white-space: nowrap;
  font-weight: 600;
  font-size: 1.8rem;
  font-family: var(--font-poppins);
  display: flex;
  align-items: center;
}

.lane-wrapper {
  padding-top: 2rem;
  flex: 1 1 auto;
  max-width: 361px;
}

.applications-container {
  position: relative;
  padding: 1rem 2rem 0 2rem;
  max-height: calc(100vh - var(--top-margin));
  overflow: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  background: none;
}

.sub-header {
  padding: 0 2rem 0 2rem;
  min-width: 24rem;
}

.app-date {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 0.3rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-group > *:nth-last-child(1) {
  margin-bottom: 3rem;
}
</style>
