function getSelectedMemberIndex(part) {
  if (!part) {
    return 0;
  }
  return part.activeMember || 0;
}

function getFullIdPath(part, documentsCollection) {
  if (!documentsCollection) {
    return '';
  }
  if (documentsCollection[part.id]) {
    return part.id;
  }
  let index = getSelectedMemberIndex(part);
  let applicantId = `${part.id}-${index}`;
  if (part.members.length === 1) {
    applicantId = part.members[0].id;
  }
  return applicantId;
}

function getFirstWord(str) {
  return str.trim().split(/\s+/)[0] || '';
}

export { getFullIdPath, getSelectedMemberIndex, getFirstWord };
