<template>
  <div
    v-outside-click="closeDropdown"
    class="app-switcher-dropdown"
    :class="{ expanded: isDropdownOpen }"
  >
    <button
      class="selected-option dropdown-link"
      type="button"
      :disabled="!nonActiveBtns.length"
      @click="toggleDropdown"
    >
      <div class="icon-text-container">
        {{ selectedOption }}
      </div>
      <div v-if="nonActiveBtns.length" class="arrow-container">
        <div
          class="arrow"
          :class="{ up: isDropdownOpen, down: !isDropdownOpen }"
        ></div>
      </div>
    </button>
    <div v-if="nonActiveBtns.length" class="options-list">
      <button
        v-for="nonActiveBtn in nonActiveBtns"
        :key="nonActiveBtn.id"
        type="button"
        class="dropdown-link link"
        @click="handleClick(nonActiveBtn.id)"
      >
        {{ nonActiveBtn.label }}
        <LockIcon v-if="nonActiveBtn.isLocked" class="lock-icon" />
      </button>
    </div>
    <SubletInfoModal
      v-if="!ahAccess && ahAccessModalVisible"
      @close="
        ahAccessModalVisible = false;
        isDropdownOpen = false;
      "
    />
    <OvPrivateInfoModal
      v-if="!ovPrivateAccess && ovPrivateAccessModalVisible"
      @close="
        ovPrivateAccessModalVisible = false;
        isDropdownOpen = false;
      "
    />
    <OvCompanyInfoModal
      v-if="!ovCompanyAccess && ovCompanyAccessModalVisible"
      @close="
        ovCompanyAccessModalVisible = false;
        isDropdownOpen = false;
      "
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { APP_NAMES, APP_PATHS } from '@/utils/constants';
import outsideClick from '@/directives/outside-click';
import SubletInfoModal from '@/components/SubletInfoModal.vue';
import OvPrivateInfoModal from '@/components/OvPrivateInfoModal.vue';
import OvCompanyInfoModal from '@/components/OvCompanyInfoModal.vue';
import LockIcon from './LockIcon.vue';

export default {
  name: 'AppSwitcherDropdown',
  directives: { outsideClick },
  components: {
    SubletInfoModal,
    OvPrivateInfoModal,
    OvCompanyInfoModal,
    LockIcon
  },
  data() {
    return {
      APP_NAMES,
      APP_PATHS,
      isDropdownOpen: false,
      ahAccessModalVisible: false,
      ovPrivateAccessModalVisible: false,
      ovCompanyAccessModalVisible: false
    };
  },
  computed: {
    ...mapGetters({
      ahAccess: 'app/ahAccess',
      baAccess: 'app/baAccess',
      ovPrivateAccess: 'app/ovPrivateAccess',
      ovCompanyAccess: 'app/ovCompanyAccess'
    }),
    ...mapState({
      activeApp: state => state.app.activeApp
    }),
    selectedOption() {
      if (this.activeApp === this.APP_NAMES.BYTESANSOKAN) {
        return 'Lägenhetsbyten';
      }
      if (this.activeApp === this.APP_NAMES.ANDRAHAND) {
        return 'Andrahand';
      }
      if (this.activeApp === this.APP_NAMES.OVERLATELSER_PRIVATE) {
        return 'Privata överlåtelser';
      }
      if (this.activeApp === this.APP_NAMES.OVERLATELSER_COMPANY) {
        return 'Företagsöverlåtelser';
      }
      return '';
    },
    buttons() {
      return [
        {
          label: 'Lägenhetsbyten',
          id: this.APP_NAMES.BYTESANSOKAN,
          isLocked: !this.baAccess
        },
        {
          label: 'Andrahand',
          id: this.APP_NAMES.ANDRAHAND,
          isLocked: !this.ahAccess
        },
        {
          label: 'Privata överlåtelser',
          id: this.APP_NAMES.OVERLATELSER_PRIVATE,
          isLocked: !this.ovPrivateAccess
        },
        {
          label: 'Företagsöverlåtelser',
          id: this.APP_NAMES.OVERLATELSER_COMPANY,
          isLocked: !this.ovCompanyAccess
        }
      ];
    },
    nonActiveBtns() {
      return this.buttons.filter(button => button.id !== this.activeApp);
    }
  },
  methods: {
    ...mapActions({
      updateActiveApp: 'app/updateActiveApp'
    }),
    showAhAccessModal() {
      this.ahAccessModalVisible = true;
    },
    showOvPrivateAccessModal() {
      this.ovPrivateAccessModalVisible = true;
    },
    showOvCompanyAccessModal() {
      this.ovCompanyAccessModalVisible = true;
    },
    handleClick(id) {
      this.closeDropdown();
      if (id === this.APP_NAMES.ANDRAHAND) {
        if (!this.ahAccess) {
          this.showAhAccessModal();
          return;
        }
        this.updateActiveApp(id);
        this.$router.push(`/${this.APP_PATHS.ANDRAHAND}/alla`);
      }

      if (id === this.APP_NAMES.BYTESANSOKAN) {
        if (!this.baAccess) {
          return;
        }
        this.updateActiveApp(id);
        this.$router.push(`/${this.APP_PATHS.BYTESANSOKAN}/alla`);
      }

      if (id === this.APP_NAMES.OVERLATELSER_PRIVATE) {
        if (!this.ovPrivateAccess) {
          this.showOvPrivateAccessModal();
          return;
        }
        this.updateActiveApp(id);
        this.$router.push(`/${this.APP_PATHS.OVERLATELSER_PRIVATE}/alla`);
      }
      if (id === this.APP_NAMES.OVERLATELSER_COMPANY) {
        if (!this.ovCompanyAccess) {
          this.showOvCompanyAccessModal();
          return;
        }
        this.updateActiveApp(id);
        this.$router.push(`/${this.APP_PATHS.OVERLATELSER_COMPANY}/alla`);
      }
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }
};
</script>

<style scoped>
.app-switcher-dropdown {
  outline: none;
  background-color: #fff;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}
.app-switcher-dropdown.expanded {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-container .arrow {
  border: solid var(--color-grey);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
}
.arrow-container .arrow.down {
  transform: rotate(45deg);
}
.arrow-container .arrow.up {
  transform: rotate(-135deg);
}
.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--color-white);
  color: var(--color-grey);
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 1rem;
  user-select: none;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-light-grey);
}
.expanded .selected-option {
  border-radius: 1rem 1rem 0rem 0rem;
}
.selected-option .icon-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5px;
}
.selected-option[disabled] {
  cursor: default;
}
.selected-option[disabled]:hover {
  color: var(--color-grey);
}
.selected-option[disabled]:hover :deep(svg path) {
  fill: var(--color-grey);
}
.options-list {
  background-color: var(--color-white);
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  border: 1px solid var(--color-light-grey);
  border-top: 0;
  z-index: 1;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 0;
}
.options-list .link {
  display: flex;
  align-items: center;
  color: var(--color-grey);
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 0 0 1rem 1rem;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expanded .options-list {
  opacity: 1;
  visibility: visible;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
.dropdown-link :deep(svg path) {
  fill: var(--color-grey);
}
.dropdown-link:hover {
  color: var(--color-blue);
}
.dropdown-link:hover :deep(svg path) {
  fill: var(--color-blue);
}
</style>
