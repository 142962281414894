import { render, staticRenderFns } from "./OvRemoveModal.vue?vue&type=template&id=c7331f22&scoped=true"
import script from "./OvRemoveModal.vue?vue&type=script&lang=js"
export * from "./OvRemoveModal.vue?vue&type=script&lang=js"
import style0 from "./OvRemoveModal.vue?vue&type=style&index=0&id=c7331f22&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7331f22",
  null
  
)

export default component.exports