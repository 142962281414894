<template>
  <ApplicationCard
    :type="reasonTypeTitle"
    :to="getPath"
    :status="application.landlordStatus"
    :subtitle="subtitle(application.landlordStatus)"
    :subtitle-class="subtitleClass(application.landlordStatus)"
    :is-new="isNew"
    :full-name="fullName"
    :last-name="lastName"
    :status-date="statusDate"
    :unread-notes="application?.unreadNotes"
    :closed-statuses="closedStatuses"
    :application-id="`${application.id}`"
    :application-tags="application.tags"
    :show-tag-button="showTagButton"
    :enable-link="enableLink"
  >
    <template slot="body">
      <div class="application-card-info-container">
        <div class="application-card-info">
          <template v-if="isCompanyApplication">
            <p>
              <span class="capitalize-first">Överlåtaren</span>
              <span class="light"
                >{{ application.currentTenants[0]?.organizationNumber }} ({{
                  application.currentTenants[0]?.name
                }})</span
              >
            </p>
            <p>
              <span class="capitalize-first">Lokalens adress</span>
              <span class="light"
                >{{ application.apartment.address }},
                {{ application.apartment.postalCode }}
                {{ application.apartment.city }}</span
              >
            </p>
            <p>
              <span class="capitalize-first">Hyreskontraktnr.</span>
              <span class="light">{{
                application.apartment.contractNumber
              }}</span>
            </p>
            <p>
              <span class="capitalize-first">Övertagaren</span>
              <span class="light"
                >{{ application.secondaryTenants[0]?.organizationNumber }} ({{
                  application.secondaryTenants[0]?.name
                }})</span
              >
            </p>
          </template>
          <template v-else>
            <p>
              <span class="capitalize-first">{{
                currentTenantsNameGroup
              }}</span>
              <span class="light">{{ application.apartment?.address }}</span>
            </p>
            <p v-if="application.secondaryTenants[0]">
              <span class="capitalize-first">{{
                application.secondaryTenants[0].name
              }}</span>
              <span class="light">{{
                application.secondaryTenants[0].address
              }}</span>
            </p>
            <p v-if="application.secondaryTenants[1]">
              <span class="capitalize-first">{{
                application.secondaryTenants[1].name
              }}</span>
              <span class="light">{{
                application.secondaryTenants[1].address
              }}</span>
            </p>
            <p>Hyra: {{ application.apartment?.rent }} kr</p>
            <p>Lägenhetsnr: {{ application.apartment?.apartmentNumber }}</p>
            <p>
              Objektnr:
              {{ application.apartment?.objectNumber }}
            </p>
          </template>
        </div>
      </div>
    </template>
    <template slot="footer">
      <p v-if="application.landlordStatus === 'APPROVED'">
        <i v-if="showTransferDate">{{ transferDateMsg }}</i>
      </p>
      <p v-else-if="application?.reason.transferDate">
        Önskat överlåtelsedatum:
        <br />
        <b
          >{{
            new Date(application?.reason.transferDate).toLocaleDateString(
              'sv-SE'
            )
          }}
        </b>
      </p>
    </template>
  </ApplicationCard>
</template>

<script>
import statusTypes from '@/utils/statusTypes';

import ApplicationCard from '@/components/ApplicationCard.vue';

export default {
  name: 'OvApplicationCard',
  components: {
    ApplicationCard
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    fullName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    statusDate: {
      type: String,
      default: ''
    },
    closedStatuses: {
      type: Array,
      default: () => []
    },
    reasonTypeTitle: {
      type: String,
      required: true
    },
    toPath: {
      type: String,
      default: ''
    },
    showTagButton: {
      type: Boolean,
      default: true
    },
    showTransferDate: {
      type: Boolean,
      default: true
    },
    enableLink: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getPath() {
      return `${this.toPath}/${this.application.id}`;
    },
    currentTenantsNameGroup() {
      const tenant1Name = this.application.currentTenants[0]?.name;
      const tenant2Name = this.application.currentTenants[1]?.name;

      if (tenant1Name && tenant2Name) {
        return `${tenant1Name} & ${tenant2Name}`;
      } else if (tenant1Name) {
        return tenant1Name;
      }
      return '';
    },
    transferDateMsg() {
      return `Överlåtelsedatum: ${new Date(
        this.application.actualTransferDate
      ).toLocaleDateString('sv-SE')}`;
    },
    isCompanyApplication() {
      return this.application.applicationType === 'ov-company';
    }
  },
  methods: {
    subtitle(landlordStatus) {
      let text = '';
      switch (landlordStatus) {
        case statusTypes.CLOSED:
          text = 'Avslutad';
          break;
        case statusTypes.APPROVED:
          text = 'Godkänd';
          break;
        case statusTypes.REJECTED:
          text = 'Nekad';
          break;
        case statusTypes.ABORTED:
          text = 'Avbruten';
          break;
        default:
          text = '';
      }
      return text;
    },
    subtitleClass(landlordStatus) {
      switch (landlordStatus) {
        case statusTypes.CLOSED:
          return 'closed-title';
        case statusTypes.APPROVED:
          return 'closedApproved-title';
        case statusTypes.REJECTED:
          return 'rejected-title';
        case statusTypes.ABORTED:
          return 'aborted-title';
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
.application-card-info-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 0.2rem;
  color: var(--color-dark-blue);
  font-size: clamp(1.2rem, 0.7vw, 1.4rem);
  font-weight: 600;
}
.application-card-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.light {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 600;
  margin-left: 0.2rem;
}
</style>
