<template>
  <ApplicationsRow two-columns>
    <ApplicationCard :dark-bg="activePartOneIndex === 1">
      <template slot="top-content">
        <h4>Överlåtaren</h4>
        <PersonCard
          :name="currentTenantCompanyInfo?.name || ''"
          :address="currentTenantCompanyInfo?.address || ''"
        />

        <MemberSelector
          :items="[
            {
              label: 'Företaget'
            },
            ...currentTenants.map((tenant, index) => ({
              ...tenant,
              label: `Firmatecknare ${index + 1}`
            }))
          ]"
          :active-index="activePartOneIndex"
          @selectItemIndex="selectCurrentTenantIndex"
        />
      </template>
      <template v-if="activePartOneIndex === 0">
        <h3 class="name capitalize-first">
          {{ currentTenantCompanyInfo?.name || '' }}
        </h3>
        <CompanyModule :company="currentTenantCompanyInfo" />
        <hr class="divider" />
        <div class="card-content">
          <h4>Företagets verksamhet</h4>
          <p>{{ currentTenantCompanyInfo?.activities || '' }}</p>
        </div>
      </template>
      <template v-else>
        <h3 class="name capitalize-first">
          {{ activeCurrentTenant?.name || '' }}
        </h3>
        <ul class="apartment-info">
          <li><b>E-post:</b> {{ activeCurrentTenant?.email || '' }}</li>
          <li>
            <b>Telefonnr.:</b>
            {{ activeCurrentTenant.phone }}
          </li>
          <li><b>Adress:</b> {{ activeCurrentTenant?.address || '' }}</li>
          <li><b>Postnr.:</b> {{ activeCurrentTenant?.postalCode || '' }}</li>
          <li><b>Ort:</b> {{ activeCurrentTenant?.city || '' }}</li>
        </ul>
      </template>
      <hr class="divider" />
      <div class="card-content">
        <h4>Verksamhet som ska bedrivas i lokalen</h4>
        <p>{{ application.reason.text }}</p>
      </div>
      <hr class="divider" />
      <OvApplicationCheckboxes
        :files="application.files"
        :application="application"
        :application-id="application.id"
        :checked-files="application.checkedFiles"
        :other-checkboxes="otherCheckboxes"
        :application-status="application.landlordStatus"
        @openFile="$emit('openFile', $event)"
        @checksChange="$emit('checksChange', $event)"
      />
    </ApplicationCard>
    <ApplicationCard :dark-bg="activePartTwoIndex === 1">
      <template slot="top-content">
        <h4>Övertagaren</h4>
        <PersonCard
          :name="secondaryTenantCompanyInfo?.name || ''"
          :address="secondaryTenantCompanyInfo?.address || ''"
        />

        <MemberSelector
          :items="[
            {
              label: 'Företaget'
            },
            ...secondaryTenants.map((tenant, index) => ({
              ...tenant,
              label: `Firmatecknare ${index + 1}`
            }))
          ]"
          :active-index="activePartTwoIndex"
          @selectItemIndex="selectSecondaryTenantIndex"
        />
      </template>
      <template v-if="activePartTwoIndex === 0">
        <h3 class="name capitalize-first">
          {{ secondaryTenantCompanyInfo?.name || '' }}
        </h3>
        <CompanyModule :company="secondaryTenantCompanyInfo" />
        <hr class="divider" />
        <div class="card-content">
          <h4>Företagets verksamhet</h4>
          <p>{{ secondaryTenantCompanyInfo?.activities || '' }}</p>
        </div>
      </template>
      <template v-else>
        <h3 class="name capitalize-first">
          {{ activeSecondaryTenant?.name || '' }}
        </h3>
        <ul class="apartment-info">
          <li><b>E-post:</b> {{ activeSecondaryTenant?.email || '' }}</li>
          <li>
            <b>Telefonnr.:</b>
            {{ activeSecondaryTenant.phone }}
          </li>
          <li><b>Adress:</b> {{ activeSecondaryTenant?.address || '' }}</li>
          <li><b>Postnr.:</b> {{ activeSecondaryTenant?.postalCode || '' }}</li>
          <li><b>Ort:</b> {{ activeSecondaryTenant?.city || '' }}</li>
        </ul>
      </template>
      <hr class="divider" />
      <OvApplicationCheckboxes
        :files="application.files"
        :application="application"
        :application-id="application.id"
        :checked-files="application.checkedFiles"
        :application-status="application.landlordStatus"
        :is-transferor="false"
        @openFile="$emit('openFile', $event)"
        @checksChange="$emit('checksChange', $event)"
      />
    </ApplicationCard>
  </ApplicationsRow>
</template>
<script>
import PersonCard from '@/components/PersonCard.vue';
import MemberSelector from '@/components/MemberSelector.vue';
import OvApplicationCheckboxes from '@/components/OvApplicationCheckboxes.vue';
import ApplicationCard from './ApplicationCard.vue';
import ApplicationsRow from './ApplicationsRow.vue';
import CompanyModule from './CompanyModule.vue';

export default {
  components: {
    CompanyModule,
    PersonCard,
    ApplicationsRow,
    ApplicationCard,
    OvApplicationCheckboxes,
    MemberSelector
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    otherCheckboxes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activePartOneIndex: 0,
      activePartTwoIndex: 0
    };
  },
  computed: {
    secondaryTenants() {
      return this.application.secondaryTenants;
    },
    currentTenants() {
      return this.application.currentTenants;
    },
    activeCurrentTenant() {
      return this.currentTenants[this.activePartOneIndex - 1];
    },
    activeSecondaryTenant() {
      return this.secondaryTenants[this.activePartTwoIndex - 1];
    },
    currentTenantCompanyInfo() {
      return this.getCompanyInfo(this.currentTenants[0].organizationNumber);
    },
    secondaryTenantCompanyInfo() {
      return this.getCompanyInfo(this.secondaryTenants[0].organizationNumber);
    }
  },
  methods: {
    getCompanyInfo(orgNr) {
      return this.application.companies[orgNr];
    },
    selectSecondaryTenantIndex(index) {
      this.activePartTwoIndex = index;
    },
    selectCurrentTenantIndex(index) {
      this.activePartOneIndex = index;
    }
  }
};
</script>
<style scoped>
.divider {
  background-color: #e4e5e8;
  margin: 2rem 0;
  border: none;
  height: 1px;
}
h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.5rem;
}
.apartment-info li {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
}
.apartment-info li b {
  font-weight: 600;
}
</style>
