<template>
  <div class="main">
    <div class="title-container">
      <h1 class="title">{{ activeAppTitle }}</h1>
    </div>
    <slot />
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'DefaultPage',
  computed: {
    ...mapState({
      activeApp: state => state.app.activeApp
    }),
    activeAppTitle() {
      switch (this.activeApp) {
        case 'andrahand':
          return 'Andrahandsuthyrning';
        case 'bytesansokan':
          return 'Lägenhetsbyten';
        case 'overlatelser':
          return 'Överlåtelser';
        default:
          return '';
      }
    }
  }
};
</script>
<style scoped>
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title-container {
  margin-left: 4rem;
  margin: 2rem 0 0 3.2rem;
}

.title {
  color: var(--color-grey);
}
</style>
