<template>
  <ApplicationsRow two-columns>
    <ApplicationCard :dark-bg="activeCurrentTenant.index === 1">
      <template slot="top-content">
        <h4>
          {{
            currentTenants.length > 1
              ? activeCurrentTenant.index === 0
                ? 'Överlåtare 1'
                : 'Överlåtare 2'
              : 'Överlåtaren'
          }}
        </h4>
        <PersonCard :name="activeCurrentTenant.name" />
        <MemberSelector
          v-if="currentTenants.length > 1"
          :items="
            currentTenants.map((tenant, index) => ({
              ...tenant,
              label: tenant.name
            }))
          "
          :active-index="activeCurrentTenantIndex"
          @selectItemIndex="selectCurrentTenantIndex"
        />
      </template>
      <h3 :key="activeCurrentTenant.name" class="name capitalize-first">
        {{ activeCurrentTenant.name }}
      </h3>
      <ul class="apartment-info">
        <li><b>Personnr.:</b> {{ activeCurrentTenant.ssn }}</li>
        <li><b>E-post:</b> {{ activeCurrentTenant.email }}</li>
        <li>
          <b>Telefonnr.:</b>
          {{ activeCurrentTenant.phone }}
        </li>
        <li><b>Adress:</b> {{ activeCurrentTenant.address }}</li>
        <li><b>Postnr.:</b> {{ activeCurrentTenant.postalCode }}</li>
        <li><b>Ort:</b> {{ activeCurrentTenant.city }}</li>
      </ul>
      <hr class="divider" />
      <OvApplicationCheckboxes
        :files="application.files"
        :application="application"
        :application-id="application.id"
        :checked-files="application.checkedFiles"
        :other-checkboxes="otherCheckboxes"
        :application-status="application.landlordStatus"
        @openFile="$emit('openFile', $event)"
        @checksChange="$emit('checksChange', $event)"
      />
      <br />
      <div class="card-content">
        <h4>Skäl</h4>
        <p>{{ getReasonText }}</p>
      </div>
      <br />
      <div class="card-content">
        <h4>Anledning</h4>
        <p>{{ application.reason.text }}</p>
      </div>
    </ApplicationCard>
    <ApplicationCard :dark-bg="activeSecondaryTenant.index === 1">
      <template slot="top-content">
        <h4>
          {{
            secondaryTenants.length > 1
              ? activeSecondaryTenant.index === 0
                ? 'Övertagare 1'
                : 'Övertagare 2'
              : 'Övertagaren'
          }}
        </h4>
        <PersonCard :name="activeSecondaryTenant.name" />
        <MemberSelector
          v-if="secondaryTenants.length > 1"
          :items="
            secondaryTenants.map((tenant, index) => ({
              ...tenant,
              label: tenant.name
            }))
          "
          :active-index="activeCurrentTenantIndex"
          @selectItemIndex="selectSecondaryTenantIndex"
        />
      </template>
      <h3 :key="activeSecondaryTenant.name" class="name capitalize-first">
        {{ activeSecondaryTenant.name }}
      </h3>
      <ul class="apartment-info">
        <li><b>Personnr.:</b> {{ activeSecondaryTenant.ssn }}</li>
        <li><b>E-post:</b> {{ activeSecondaryTenant.email }}</li>
        <li>
          <b>Telefonnr.:</b>
          {{ activeSecondaryTenant.phone }}
        </li>
        <li><b>Adress:</b> {{ activeSecondaryTenant.address }}</li>
        <li><b>Postnr.:</b> {{ activeSecondaryTenant.postalCode }}</li>
        <li><b>Ort:</b> {{ activeSecondaryTenant.city }}</li>
      </ul>
      <hr class="divider" />
      <OvApplicationCheckboxes
        :files="application.files"
        :application="application"
        :application-id="application.id"
        :checked-files="application.checkedFiles"
        :application-status="application.landlordStatus"
        :is-transferor="false"
        @openFile="$emit('openFile', $event)"
        @checksChange="$emit('checksChange', $event)"
      />
    </ApplicationCard>
  </ApplicationsRow>
</template>
<script>
import { ovReasonText } from '@/utils/ovUtils';

import PersonCard from '@/components/PersonCard.vue';
import MemberSelector from '@/components/MemberSelector.vue';
import OvApplicationCheckboxes from '@/components/OvApplicationCheckboxes.vue';
import ApplicationCard from './ApplicationCard.vue';
import ApplicationsRow from './ApplicationsRow.vue';

export default {
  components: {
    PersonCard,
    ApplicationsRow,
    ApplicationCard,
    OvApplicationCheckboxes,
    MemberSelector
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    otherCheckboxes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      ovReasonText,
      activeCurrentTenantIndex: 0,
      activeSecondaryTenantIndex: 0
    };
  },
  computed: {
    secondaryTenants() {
      return this.application.secondaryTenants;
    },
    currentTenants() {
      return this.application.currentTenants;
    },
    activeCurrentTenant() {
      return this.currentTenants[this.activeCurrentTenantIndex];
    },
    activeSecondaryTenant() {
      return this.secondaryTenants[this.activeSecondaryTenantIndex];
    },
    getReasonText() {
      return this.ovReasonText(this.application.reason);
    }
  },
  methods: {
    selectSecondaryTenantIndex(index) {
      this.activeSecondaryTenantIndex = index;
    },
    selectCurrentTenantIndex(index) {
      this.activeCurrentTenantIndex = index;
    }
  }
};
</script>
<style scoped>
.divider {
  background-color: #e4e5e8;
  margin: 2rem 0;
  border: none;
  height: 1px;
}
h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.5rem;
}
.apartment-info li {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
}
.apartment-info li b {
  font-weight: 600;
}
</style>
